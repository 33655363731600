<template>
  <div>
    <div v-if="props.title" class="mb-xs gap-xs flex items-center">
      <div class="text-2xl font-bold">
        {{ props.title }}
      </div>
      <IconButton
        v-if="isFoldable"
        :icon="isContentUnfolded ? Icon.UP_ARROW : Icon.DOWN_ARROW"
        class="!h-md"
        @click="isContentUnfolded = !isContentUnfolded"
      />
    </div>
    <div class="gap-xs px-sm grid" :class="{ '!hidden': !isContentUnfolded }">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "../assets/icons/Icon";
import IconButton from "../components/IconButton.vue";

const props = defineProps<{
  title?: undefined | string;
  isFoldable?: true;
}>();

const isContentUnfolded = defineModel<undefined | boolean>({
  default: true,
});
</script>
