<template>
  <WrapperBox>
    <template v-if="!isLoadingView && !error">
      <NavigationComponent v-if="$slots.navigationButtons || shouldShowActivityLogButton" class="mb-lg md:mb-xl">
        <slot name="navigationButtons" />
        <ActivityLogLinkButton v-if="shouldShowActivityLogButton" :current-route-activity-log-metadata />
      </NavigationComponent>
      <slot name="title">
        <HeaderText class="mb-lg md:mb-xl">
          {{ props.title }}
        </HeaderText>
      </slot>
      <div class="gap-sm md:gap-md grid">
        <slot />
      </div>
    </template>
    <div v-if="isLoadingView">
      {{ $t("message.loading") }}
    </div>
    <div v-if="error">
      <HeaderText>
        {{ $t("message.pageCouldNotBeLoaded") }}
      </HeaderText>
      {{ error.message }}
    </div>
  </WrapperBox>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import ActivityLogLinkButton from "../activityLog/components/ActivityLogLinkButton.vue";
import HeaderText from "../components/HeaderText.vue";
import NavigationComponent from "../components/NavigationComponent.vue";
import WrapperBox from "../components/WrapperBox.vue";
import { useUser } from "../composition-functions/user";
import { UserType } from "../composition-functions/user/UserType";
import { useView } from "../composition-functions/view";

const { userType } = useUser();

const currentRoute = useRoute();

const currentRouteActivityLogMetadata = computed(() => {
  return currentRoute.meta.activityLogs ? currentRoute.meta.activityLogs(currentRoute) : undefined;
});

const shouldShowActivityLogButton = computed(
  () => currentRouteActivityLogMetadata.value && userType.value === UserType.SUPER_ADMIN,
);

const props = defineProps<{
  title: string;
  onMountedHandler?: () => void | Promise<void>;
}>();

const { isLoadingView, error } = useView(props.onMountedHandler, () => props.title);
</script>
