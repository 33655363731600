<template>
  <div
    v-for="{ key, title: header, attributes } in columns"
    :key="key"
    :class="getClassesFromAttributes(attributes)"
    class="my-xs px-xs xs:flex grid items-center md:table-cell md:flex-none md:border-[1px] md:p-1 md:align-middle lg:whitespace-nowrap dark:border-transparent"
    :data-label="header"
  >
    <span class="mr-xs font-bold md:hidden"> {{ header }}: </span>
    <component :is="slots![key]" />
  </div>
</template>

<script lang="ts">
import { ComponentInternalInstance, InjectionKey } from "vue";

import { getClassesFromAttributes, ListComponentColumn } from "../components/ListComponent.vue";

export const ColumnsInjectionKey: InjectionKey<ListComponentColumn[]> = Symbol("Columns");
export const SlotsInjectionKey: InjectionKey<ComponentInternalInstance["slots"]> = Symbol("Slots");
</script>

<script setup lang="ts">
import { inject } from "vue";

const { columns, slots } = getSlotsandColumns();

function getSlotsandColumns() {
  const columns = inject(ColumnsInjectionKey);
  const slots = inject(SlotsInjectionKey);

  if (!columns) {
    throw Error("No columns");
  }

  if (!slots) {
    throw Error("No slots");
  }

  const slotNames = Object.keys(slots);
  const columnKeys = columns.map((label) => label.key);

  if (columnKeys.some((columnKey) => slotNames.includes(columnKey) === false)) {
    throw Error("Missing slots");
  }

  return {
    slots,
    columns,
  };
}
</script>
