<template>
  <ErrorWrapper :instance-path="props.instancePath">
    <template #default="{ errors }">
      <div v-bind="$attrs" :data-has-errors="!!errors">
        <slot name="title">
          <div class="flex">
            <span v-if="info" v-tippy="{ content: props.info ? props.info : '' }" class="cursor-pointer"> &#9432;&nbsp; </span>
            <FormElementTitle v-if="title" class="text-left"> {{ title }}: </FormElementTitle>
            <template v-for="legend in props.legends" :key="legend">
              <LegendHint :legend="legend" />
            </template>
            <div class="flex-grow" />
          </div>
        </slot>
        <div class="w-full">
          <slot />
        </div>
        <FormElementErrors v-if="!shouldHideErrorMessage" />
      </div>
    </template>
  </ErrorWrapper>
</template>

<script setup lang="ts">
import ErrorWrapper from "../components/ErrorWrapper.vue";
import FormElementErrors from "../components/FormElementErrors.vue";
import FormElementTitle from "../components/FormElementTitle.vue";
import LegendHint, { Legend } from "./LegendHint.vue";

const props = defineProps<{
  title?: undefined | string;
  instancePath?: undefined | string;
  info?: undefined | string;
  legends?: undefined | Legend[];
  shouldHideErrorMessage?: true;
}>();
</script>
