export type DebounceAsyncParams<A extends unknown[], R, TFunction extends (...args: A) => Promise<R>> = {
  functionToCall: TFunction;
  timeout?: number;
};

export function debounceAsync<A extends unknown[], R, TFunction extends (...args: A) => Promise<R>>({
  functionToCall,
  timeout = 300,
}: DebounceAsyncParams<A, R, TFunction>) {
  let timer: number;
  const pendingPromises: Set<() => void> = new Set();

  return (...args: Parameters<TFunction>) => {
    window.clearTimeout(timer);

    pendingPromises.forEach((resolve) => resolve());

    return new Promise<void | R>((resolve, reject) => {
      pendingPromises.add(resolve);

      timer = window.setTimeout(() => {
        pendingPromises.delete(resolve);

        functionToCall(...args)
          .then(resolve)
          .catch(reject);
      }, timeout);
    });
  };
}
