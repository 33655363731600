<template>
  <span
    v-tippy="{ content: props.legend.tooltip ? props.legend.tooltip : '' }"
    class="ml-xs text-lightTertiaryColor dark:text-darkTertiaryColor text-[75%] hover:cursor-help"
    :class="{
      'text-primary dark:text-primary': props.legend.isHighlighted,
    }"
  >
    [{{ props.legend.tag }}]
  </span>
</template>

<script setup lang="ts">
export type Legend = {
  tag: string;
  text: string;
  tooltip?: string;
  isHighlighted?: boolean;
};

const props = defineProps<{
  legend: Legend;
}>();
</script>
