<style lang="scss" scoped>
.rowClass {
  @apply odd:bg-lightPrimaryColor even:bg-lightSecondaryColor dark:odd:bg-darkPrimaryColor dark:even:bg-darkSecondaryColor md:h-xl table-row hover:bg-white/5 dark:hover:bg-white/20;
}
</style>
<template>
  <a v-if="isExternalLink(props.to)" :href="props.to as string" class="rowClass" style="cursor: pointer">
    <ListComponentColumns />
  </a>
  <RouterLink v-else class="rowClass" style="cursor: pointer" :to="props.to">
    <ListComponentColumns />
  </RouterLink>
</template>

<script setup lang="ts">
import { getCurrentInstance, provide } from "vue";
import { RouteLocationRaw } from "vue-router";

import ListComponentColumns, { SlotsInjectionKey } from "../components/ListComponentColumns.vue";
import { isExternalLink } from "../util/isExternalLink";

const props = defineProps<{
  to: RouteLocationRaw;
}>();

const currentInstance = getCurrentInstance();

if (!currentInstance) {
  throw new Error("No instance");
}

provide(SlotsInjectionKey, currentInstance.slots);
</script>
