<template>
  <div class="mb-sm gap-sm flex flex-grow items-center justify-end">
    <BaseIcon class="h-md cursor-pointer dark:invert" :icon="Icon.HOME_ICON_SILHOUETTE" @click="$router.push({ name: 'home' })" />
    <div class="grow" />
    <router-link
      v-if="currentMandant?.hasOpenChecklistTasks === true"
      :to="{ name: 'checklist', params: { mandantId: currentMandant.id } }"
      class="hover:bg-primary/20 rounded-xl bg-inherit"
      :title="$t('message.checklist')"
    >
      <BaseIcon class="h-lg cursor-pointer p-1" :icon="Icon.WARNING" />
    </router-link>
    <router-link
      v-if="currentMandant && shouldShowSensitiveMandantData()"
      :to="{ name: 'notifications' }"
      :title="$t('message.notifications')"
    >
      <NotificationTag />
    </router-link>
    <a
      v-if="docsUrl"
      class="hover:bg-primary/20 rounded-xl bg-inherit"
      :title="$t('message.toTheDocumentation')"
      :href="docsUrl"
      target="_blank"
    >
      <BaseIcon class="h-lg cursor-pointer p-1 dark:invert" :icon="Icon.QUESTION_MARK" />
    </a>
    <UserMenu />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { Icon } from "../assets/icons/Icon";
import BaseIcon from "../components/BaseIcon.vue";
import UserMenu from "../components/UserMenu.vue";
import { useMandant } from "../mandant/service.mandant";
import NotificationTag from "../notification/components/NotificationTag.vue";
import { shouldShowSensitiveMandantData } from "../users/service.user";

const currentRoute = useRoute();

const { currentMandant } = useMandant();

const docsUrl = computed(() => {
  if (currentRoute.meta.docsUrl) {
    return currentRoute.meta.docsUrl(currentRoute);
  } else {
    return undefined;
  }
});
</script>
