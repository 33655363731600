<template>
  <div class="mb-sm md:mb-md">
    <span
      v-for="legend in props.legends"
      :key="legend.tag"
      class="mr-sm text-lightTertiaryColor dark:text-darkTertiaryColor"
      :class="{
        'text-primary dark:text-primary': legend.isHighlighted,
      }"
    >
      <span>[{{ legend.tag + "] = " + legend.text }} </span>
    </span>
  </div>
</template>

<script setup lang="ts">
import { Legend } from "./LegendHint.vue";

const props = defineProps<{
  legends: Legend[];
}>();
</script>
