import { computed, ComputedRef } from "vue";
import { useRoute } from "vue-router";
import { config } from "../../config/config";
import { ReservationType } from "../../reservation/enum.ReservationType";

export type BackToReservationOrRequest = {
  type: ReservationType;
  link: string;
};

export const backToReservationOrRequestData: ComputedRef<null | BackToReservationOrRequest> = computed(() => {
  const route = useRoute();
  return route?.query["reservationId"]
    ? {
        type: Number(route.query["reservationType"]) as ReservationType,
        link: config.eassistantProductionUrl + "/Reservation/Edit/" + route.query["reservationId"],
      }
    : null;
});
