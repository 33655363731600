import { minimatch } from "minimatch";
import { ComputedRef, InjectionKey, Ref, computed, inject, provide, ref } from "vue";
import { GenericFailure } from "../errors/GenericFailure";
import { CustomValidationError } from "./CustomValidationError";

export type CustomErrorObject = {
  instancePath: string;
  message: string;
};

export function isAjvError(error: unknown): error is CustomErrorObject {
  return (error as CustomErrorObject).instancePath !== undefined && (error as CustomErrorObject).message !== undefined;
}

const ajvErrorsInjectionKey: InjectionKey<Ref<CustomErrorObject[]>> = Symbol("ajvErrors");

export function initAjvErrors() {
  const errors = ref<CustomErrorObject[]>([]);
  provide(ajvErrorsInjectionKey, errors);

  const provideAjvErrors = <ARGS extends Array<unknown>, RETURNTYPE>(functionToCall: (...args: ARGS) => RETURNTYPE) => {
    return async (...args: ARGS): Promise<RETURNTYPE> => {
      try {
        const result = await functionToCall(...args);
        errors.value = [];
        return result;
      } catch (error) {
        if (error instanceof GenericFailure && Array.isArray(error.errors) && error.errors.every(isAjvError)) {
          errors.value = error.errors;
          throw new CustomValidationError(error);
        }

        throw error;
      }
    };
  };

  return {
    errors,
    clearErrors: () => {
      errors.value = [];
    },
    provideAjvErrors,
  };
}

type ErrorsWithClearMethod = {
  errors: ComputedRef<undefined | CustomErrorObject[]>;
  clearErrors: () => void;
};
export const errorsInjectionKey: InjectionKey<ErrorsWithClearMethod> = Symbol("errors");

export function provideAjvErrorsForInstancePath(instancePath: () => undefined | string) {
  const { errors, clearErrors } = getAjvErrorsForInstancePath(instancePath);

  provide(errorsInjectionKey, {
    errors,
    clearErrors,
  });

  return { errors, clearErrors };
}

export function getAjvErrorsForInstancePath(instancePath: () => undefined | string) {
  const ajvErrors = inject(ajvErrorsInjectionKey, undefined);

  const errors = computed<undefined | CustomErrorObject[]>(() => {
    const instancePathValue = instancePath();
    if (!instancePathValue) {
      return undefined;
    }

    const filteredErrors = ajvErrors?.value.filter((error) => minimatch(error.instancePath, instancePathValue));
    if (!filteredErrors || filteredErrors.length <= 0) {
      return undefined;
    }

    return filteredErrors;
  });

  function clearErrors() {
    const instancePathValue = instancePath();
    if (!instancePathValue) {
      return;
    }

    if (ajvErrors) {
      const newErrors = ajvErrors.value.filter((error) => !minimatch(error.instancePath, instancePathValue));

      if (JSON.stringify(newErrors) === JSON.stringify(ajvErrors.value)) {
        return;
      }

      ajvErrors.value = newErrors;
    }
  }

  return { errors, clearErrors };
}

export function useErrors() {
  const errors = inject(errorsInjectionKey, {
    errors: computed(() => undefined),
    clearErrors: () => {
      return;
    },
  });

  return errors;
}
