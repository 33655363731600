<template>
  <div
    class="gap-xs flex w-fit flex-wrap items-center rounded-sm border border-transparent"
    :class="{
      '!border-red': errors,
      '!w-full': vertical,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { InjectionKey } from "vue";

export const RadioGroupName: InjectionKey<string> = Symbol("name");
export const RadioGroupVertical: InjectionKey<boolean> = Symbol("vertical");

let instanceCounter = 0;
</script>

<script setup lang="ts">
import { provide } from "vue";
import { useErrors } from "../errors/useErrors";

const props = withDefaults(
  defineProps<{
    name?: string;
    vertical?: boolean;
    error?: undefined | string;
  }>(),
  {
    vertical: false,
  },
);

provide(RadioGroupName, props.name ?? `RadioGroup-${++instanceCounter}`);
provide(RadioGroupVertical, props.vertical);

const { errors } = useErrors();
</script>
