<template>
  <div>
    <ListView :title="$t('pages.vatSetting.list.title')" :on-mounted-handler="onMountedHandler">
      <template #navigationButtons>
        <NavigationLinkButton
          :to="{ name: 'vatSettingEdit', params: { mandantId: mandant.id, vatSettingId: '0' } }"
          :icon="Icon.ADD"
          :title="$t('message.new')"
        />
      </template>
      <SearchComponent v-model="searchTerm" />
      <template v-if="queryResult !== null">
        <ListComponent :extract-key="(item) => item.id" :is-loading="isLoadingItems" :items="queryResult.data" :columns="columns">
          <template #default="item">
            <ListComponentLinkRow :to="{ name: 'vatSettingEdit', params: { vatSettingId: item.id } }">
              <template #state>
                <StateTag :state="item.state" />
              </template>

              <template #description>
                {{ item.description }}
              </template>

              <template #vatRate>{{ item.vatRate }}%</template>

              <template #type>
                <BaseTag v-if="item.type" class="!bg-blue">{{ $t(`vatSettingType.${item.type}`) }}</BaseTag>
              </template>

              <template #actions>
                <IconButtonColumn>
                  <IconButton
                    :icon="Icon.DELETE"
                    :tooltip-text="$t('message.deleteRecord')"
                    @click.prevent="() => deleteRecord(item)"
                  />
                </IconButtonColumn>
              </template>
            </ListComponentLinkRow>
          </template>
        </ListComponent>

        <PaginationComponent v-model="currentPage" :page-size="pageSize" :items-count="queryResult.meta.totalCount" />
      </template>
    </ListView>
  </div>
</template>

<script setup lang="ts">
import { Ref, reactive, ref, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";

import { Icon } from "../../assets/icons/Icon";
import { createSequencialFunctionCallsHandler } from "../../common/utils/createSequencialFunctionCallsHandler";
import { debounceAsync } from "../../common/utils/debounceAsync";
import BaseTag from "../../components/BaseTag.vue";
import IconButton from "../../components/IconButton.vue";
import IconButtonColumn from "../../components/IconButtonColumn.vue";
import ListComponent, { ListComponentColumn } from "../../components/ListComponent.vue";
import ListComponentLinkRow from "../../components/ListComponentLinkRow.vue";
import ListView from "../../components/ListView.vue";
import NavigationLinkButton from "../../components/NavigationLinkButton.vue";
import PaginationComponent from "../../components/PaginationComponent.vue";
import SearchComponent from "../../components/SearchComponent.vue";
import StateTag from "../../components/StateTag.vue";
import { OffsetBasedPaginationQueryResult } from "../../dtos/PaginationQueryResult";
import { IQueryParams } from "../../interfaces/IParams";
import { MandantDto } from "../../mandant/dto.mandant";
import { useMandant } from "../../mandant/service.mandant";
import { setLocalParameters, setUrlParameters } from "../../util/ListHelper";
import { getUserConfirmationAsBoolean, setToastMessage, setToastMessageLoading } from "../../util/SweetalertHelper";
import { VatSettingDto } from "../dto.vatSetting";
import { deleteVatSetting, getVatSettings } from "../service.vatSetting";

const { currentMandant } = useMandant();
const { t } = useI18n();
const mandant = currentMandant as Ref<MandantDto>;

const pageOptions = reactive<IQueryParams>({
  searchTerm: "",
  currentPage: 0,
  pageSize: 20,
});

const { currentPage, pageSize, searchTerm } = toRefs(pageOptions);

const queryResult: Ref<null | OffsetBasedPaginationQueryResult<VatSettingDto>> = ref(null);
const isLoadingItems = ref(false);

const columns: ListComponentColumn[] = [
  {
    key: "state",
    title: t("message.state"),
  },
  {
    key: "description",
    title: t("message.vatSetting.description"),
  },
  {
    key: "vatRate",
    title: t("message.vatSetting.rate"),
  },
  {
    key: "type",
    title: t("message.vatSetting.type"),
  },
  {
    key: "actions",
    title: t("message.actions"),
  },
];

async function getItemsAndCount() {
  isLoadingItems.value = true;

  await setUrlParameters({ defaultParams: pageOptions });

  const vatSettingsQueryResults = await getVatSettings({ mandantId: mandant.value.id, queryParams: pageOptions });
  queryResult.value = vatSettingsQueryResults;
  isLoadingItems.value = false;
}

async function deleteRecord(item: VatSettingDto) {
  const isConfirmed = await getUserConfirmationAsBoolean();

  if (isConfirmed) {
    setToastMessageLoading();

    if (item.id) {
      await deleteVatSetting({ vatSettingId: item.id, mandantId: mandant.value.id });

      setToastMessage({ icon: "success", title: t("message.recordDeleted") });

      await getItemsAndCount();
      pageOptions.currentPage = 0;
    }
  }
}

const debouncedGetItemsAndCount = debounceAsync({
  functionToCall: createSequencialFunctionCallsHandler({ functionToCall: getItemsAndCount, shouldReplaceLastCall: true }),
});

watch(
  () => [pageOptions.currentPage, pageOptions.pageSize],
  async () => {
    await debouncedGetItemsAndCount();
  },
);

watch(
  () => pageOptions.searchTerm,
  async () => {
    if (pageOptions.currentPage === 0 || queryResult.value === null) {
      await debouncedGetItemsAndCount();
    } else {
      pageOptions.currentPage = 0;
    }
  },
);

async function onMountedHandler() {
  await setLocalParameters({ defaultParams: pageOptions });
  await getItemsAndCount();
}
</script>
