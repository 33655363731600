<template>
  <div
    class="p-xs grid h-full grid-cols-[40px_auto] grid-rows-1 gap-3 border border-transparent hover:border-gray-600 sm:grid-cols-[56px_auto]"
    :class="{
      'opacity-50 grayscale-[50%]': props.disabled,
      'grid-cols-[70px_auto]': props.icon || $slots.icon,
      'pointer-events-none': props.disabled,
    }"
    :title="props.tooltipText !== undefined ? `${props.tooltipText}` : ''"
  >
    <div v-if="props.icon || $slots.icon" class="flex items-center justify-center">
      <slot name="icon">
        <BaseIcon v-if="props.icon" class="h-full max-h-[40px] sm:max-h-[56px]" :icon="props.icon" />
      </slot>
    </div>
    <div class="flex flex-col justify-center gap-1 leading-none">
      <div class="text-[1.1em] font-semibold">
        <slot name="title">
          {{ $props.title }}
        </slot>
      </div>
      <div
        v-if="!isNullOrWhitespace($props.subtitle)"
        class="text-lightSecondaryTextColor dark:text-darkSecondaryTextColor text-[16px]"
      >
        <slot name="subtitle">
          {{ $props.subtitle }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "../assets/icons/Icon";
import BaseIcon from "../components/BaseIcon.vue";
import { isNullOrWhitespace } from "../util/isNullOrWhitespace";

export interface NavigationActionButton {
  icon?: undefined | Icon;
  title?: undefined | string;
  subtitle?: undefined | string;
  disabled?: undefined | boolean;
  tooltipText?: undefined | string;
}
const props = defineProps<NavigationActionButton>();
</script>
