<template>
  <div v-if="totalPages > 1" class="h-lg flex justify-center gap-1">
    <BaseButton class="!min-w-[2rem]" :disabled="modelValue === 0" @click="setCurrentPage(0)">
      <BaseIcon class="h-sm" :icon="Icon.DOUBLE_PREV" />
    </BaseButton>
    <BaseButton class="!min-w-[2rem]" :disabled="modelValue === 0" @click="setCurrentPage(modelValue - 1)">
      <BaseIcon class="h-sm" :icon="Icon.PREV" />
    </BaseButton>

    <BaseButton
      v-for="number in numberButtons"
      :key="number"
      class="!min-w-[2rem]"
      :is-highlighted="modelValue === number"
      @click="setCurrentPage(number)"
    >
      <div>{{ number + 1 }}</div>
    </BaseButton>

    <BaseButton class="!min-w-[2rem]" :disabled="modelValue === totalPages - 1" @click="setCurrentPage(modelValue + 1)">
      <BaseIcon class="h-sm" :icon="Icon.NEXT" />
    </BaseButton>

    <BaseButton class="!min-w-[2rem]" :disabled="modelValue === totalPages - 1" @click="setCurrentPage(totalPages - 1)">
      <BaseIcon class="h-sm" :icon="Icon.DOUBLE_NEXT" />
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { Icon } from "../assets/icons/Icon";
import BaseButton from "../components/BaseButton.vue";
import BaseIcon from "../components/BaseIcon.vue";

const props = defineProps<{
  modelValue: number;
  pageSize: number;
  itemsCount: number;
}>();

const emit = defineEmits<{
  "update:modelValue": [number];
}>();

const maxVisibleButtons = 3;

const totalPages = computed(() => Math.ceil(props.itemsCount / props.pageSize));

const numberButtons = computed(() => {
  const sides = Math.floor(maxVisibleButtons / 2);
  const buttons = [];
  buttons.push(props.modelValue);

  // fill sides, where possible
  for (let i = 1; i <= sides; i++) {
    if (buttons.length >= maxVisibleButtons) break;
    props.modelValue + i < totalPages.value ? buttons.push(props.modelValue + i) : "";

    if (buttons.length >= maxVisibleButtons) break;
    props.modelValue - i >= 0 ? buttons.unshift(props.modelValue - i) : "";
  }

  if (buttons.length < maxVisibleButtons) {
    const firstPosition = buttons[0];
    const lastPosition = buttons[buttons.length - 1];

    for (let i = 1; i <= maxVisibleButtons; i++) {
      if (buttons.length >= maxVisibleButtons) break;
      lastPosition + i < totalPages.value ? buttons.push(lastPosition + i) : "";

      if (buttons.length >= maxVisibleButtons) break;
      firstPosition - i >= 0 ? buttons.unshift(firstPosition - i) : "";
    }
  }

  return buttons;
});

const setCurrentPage = (pageNumber: number) => {
  if (pageNumber >= 0 && pageNumber < totalPages.value) {
    emit("update:modelValue", pageNumber);
  }
};
</script>
