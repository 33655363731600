import { onMounted, ref, watch } from "vue";
import { GenericFailure } from "../../errors/GenericFailure";

export function useView(onMountedHandler?: () => void | Promise<void>, title?: () => undefined | string) {
  const isLoadingView = ref<boolean>(false);
  const error = ref<undefined | GenericFailure>(undefined);

  onMounted(async () => {
    if (onMountedHandler === undefined) return;

    try {
      isLoadingView.value = true;
      await onMountedHandler();
      isLoadingView.value = false;
    } catch (catchedError) {
      isLoadingView.value = false;

      if (catchedError instanceof GenericFailure) {
        error.value = catchedError;
      }
      throw catchedError;
    }
  });

  if (!title) {
    document.title = "Anexis CLOUD!";
  } else {
    watch(
      title,
      () => {
        document.title = title?.() || "Anexis CLOUD!";
      },
      { immediate: true },
    );
  }

  return {
    isLoadingView,
    error,
  };
}
