import { State } from "../enums/State";
import { VatSettingType } from "./enum.vatSettingType";

export type VatSettingDto = {
  id: number;
  state: State;
  vatRate: number;
  description: string;
  type: null | VatSettingType;
  cashRegisterDepartment: null | number;
};

export type VatSettingInputDto = VatSettingDto;

export function mapVatSettingDtoToVatSettingInputDto(vatSetting: VatSettingDto): VatSettingInputDto {
  return {
    id: vatSetting.id,
    state: vatSetting.state,
    vatRate: vatSetting.vatRate,
    description: vatSetting.description,
    type: vatSetting.type,
    cashRegisterDepartment: vatSetting.cashRegisterDepartment,
  };
}
