export type CreateSequencialFunctionCallsHandlerParams<TFunction extends (...args: unknown[]) => unknown> = {
  functionToCall: TFunction;
  shouldReplaceLastCall: boolean;
};

export type CreateSequencialFunctionCallsHandler = <TFunction extends (...args: unknown[]) => unknown>(
  params: CreateSequencialFunctionCallsHandlerParams<TFunction>,
) => (...args: Parameters<TFunction>) => Promise<void>;

/**
 * if the function gets called multiple times while another invocation is still running, it will run all invocations in sequence
 * @argument shouldReplaceLastCall: if an invocation is running and the function gets called multiple times, `false` will make sure ALL invocations will be invoked in sequence. `true` however will finish the currently running invocation, remove all pending invocations other than the last one and only execute that last one
 */
export const createSequencialFunctionCallsHandler: CreateSequencialFunctionCallsHandler = ({
  functionToCall,
  shouldReplaceLastCall,
}) => {
  let pendingParameters: Parameters<typeof functionToCall>[] = [];
  let isRunning = false;

  return async (...currentInvocationParameters) => {
    if (shouldReplaceLastCall) {
      pendingParameters.shift();
    }

    pendingParameters.push(currentInvocationParameters);

    if (!isRunning) {
      isRunning = true;

      try {
        while (pendingParameters.length > 0) {
          const parameters = pendingParameters.shift();

          if (parameters) {
            await functionToCall(...parameters);
          }
        }
      } finally {
        pendingParameters = [];
        isRunning = false;
      }
    }
  };
};
