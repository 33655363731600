<template>
  <button class="h-full text-left" :class="{ 'pointer-events-none': props.disabled }">
    <NavigationButtonContent
      :icon="props.icon"
      :title="props.title"
      :subtitle="props.subtitle"
      :disabled="props.disabled"
      :tooltip-text="props.tooltipText"
    >
      <template v-for="(_, field) in $slots" #[field]="data">
        <slot :name="field" v-bind="data" />
      </template>
    </NavigationButtonContent>
  </button>
</template>

<script setup lang="ts">
import { Icon } from "../assets/icons/Icon";
import NavigationButtonContent from "../components/NavigationButtonContent.vue";

export interface NavigationActionButton {
  icon?: Icon;
  title?: string;
  subtitle?: undefined | string;
  disabled?: boolean;
  tooltipText?: string;
}
const props = defineProps<NavigationActionButton>();
defineSlots<{
  [key: string]: unknown;
}>();
</script>
