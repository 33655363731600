<template>
  <BaseTag
    v-if="props.state !== State.NOT_SET"
    class="!w-[80px] !justify-center"
    :class="{
      '!bg-green': props.state === State.ACTIVE,
      '!bg-red': props.state === State.INACTIVE,
      '!bg-violet': props.state === State.DELETED,
      '!bg-blue': props.state === State.TEMPORARY,
    }"
  >
    <span v-if="props.state === State.ACTIVE">
      {{ $t("state.active") }}
    </span>
    <span v-else-if="props.state === State.INACTIVE">
      {{ $t("state.inactive") }}
    </span>
    <span v-else-if="props.state === State.DELETED">
      {{ $t("state.deleted") }}
    </span>
    <span v-else>
      {{ $t("state.temporary") }}
    </span>
  </BaseTag>
</template>

<script setup lang="ts">
import BaseTag from "../components/BaseTag.vue";
import { State } from "../enums/State";

const props = defineProps<{
  state: State;
}>();
</script>
