<template>
  <slot :errors="errors" :clear-errors="clearErrors" />
</template>

<script setup lang="ts">
import { provideAjvErrorsForInstancePath } from "../errors/useErrors";

const props = defineProps<{
  instancePath?: undefined | string;
}>();

const { errors, clearErrors } = provideAjvErrorsForInstancePath(() => props.instancePath);
</script>
