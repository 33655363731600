<template>
  <template v-if="!props.disabled && props.to !== undefined">
    <a v-if="isExternalLink(props.to)" v-bind="$attrs" :href="to as string">
      <NavigationButtonContent
        :icon="props.icon"
        :title="props.title"
        :subtitle="props.subtitle"
        :tooltip-text="props.tooltipText"
      >
        <template v-for="(_, field) in $slots" #[field]="data">
          <slot :name="field" v-bind="data" />
        </template>
      </NavigationButtonContent>
    </a>
    <RouterLink v-else :to="props.to" class="h-full">
      <NavigationButtonContent
        :icon="props.icon"
        :title="props.title"
        :subtitle="props.subtitle"
        :tooltip-text="props.tooltipText"
      >
        <template v-for="field in Object.keys($slots)" #[field]="data">
          <slot :name="field" v-bind="data" />
        </template>
      </NavigationButtonContent>
    </RouterLink>
  </template>
  <div v-else class="cursor-not-allowed">
    <NavigationButtonContent
      :icon="props.icon"
      :title="props.title"
      :subtitle="props.subtitle"
      :disabled="true"
      :tooltip-text="props.tooltipText"
    >
      <template v-for="(_, field) in $slots" #[field]="data">
        <slot :name="field" v-bind="data" />
      </template>
    </NavigationButtonContent>
  </div>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from "vue-router";

import { Icon } from "../assets/icons/Icon";
import NavigationButtonContent from "../components/NavigationButtonContent.vue";
import { isExternalLink } from "../util/isExternalLink";

const props = defineProps<{
  to: RouteLocationRaw | string;
  icon?: Icon;
  title?: string;
  subtitle?: string;
  disabled?: boolean;
  tooltipText?: string;
}>();

defineSlots<{
  [key: string]: unknown;
}>();
</script>
