<template>
  <div>
    <BaseInput ref="searchElement" v-model="value" :placeholder="$t('message.search')" class="max-w-[15rem]" />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, Ref } from "vue";

import BaseInput from "../components/BaseInput.vue";

const searchElement: Ref<null | HTMLElement> = ref(null);

const value = defineModel<string>({ required: true });

const focusSearch = () => {
  searchElement.value?.focus();
};

onMounted(() => {
  focusSearch();
});
</script>
