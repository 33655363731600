<template>
  <span
    :class="{
      '!text-red': errors,
    }"
  >
    <span class="inline-flex items-center justify-center text-xl">
      <span v-if="$slots.prepend" class="mr-xs inline-flex items-center">
        <slot name="prepend" />
      </span>
      <span
        v-tippy="{ content: props.tooltipText ? props.tooltipText : '' }"
        class="inline-flex items-center"
        :class="{ 'cursor-help border-b border-dashed': props.tooltipText }"
      >
        <slot>{{ text }}</slot>
      </span>
    </span>
  </span>
</template>

<script setup lang="ts">
import { useErrors } from "../errors/useErrors";

const props = defineProps<{
  text?: undefined | string;
  tooltipText?: undefined | string;
}>();

const { errors } = useErrors();
</script>
