import { useCommon } from "../composition-functions/common";
import { OffsetBasedPaginationQueryResult } from "../dtos/PaginationQueryResult";
import { IQueryParams } from "../interfaces/IParams";
import { VatSettingDto, VatSettingInputDto } from "./dto.vatSetting";

interface GetVatSettingParams {
  vatSettingId: string;
  mandantId: number;
}
export async function getVatSetting({ vatSettingId, mandantId }: GetVatSettingParams): Promise<VatSettingDto> {
  const { getRequest } = useCommon();

  const response = await getRequest({ path: `/admin/mandants/${mandantId}/vatSettings/${vatSettingId}` });
  const result = await response.json();
  return result;
}

interface GetVatSettingsParams {
  mandantId: number;
  queryParams: IQueryParams;
}
export async function getVatSettings({
  mandantId,
  queryParams,
}: GetVatSettingsParams): Promise<OffsetBasedPaginationQueryResult<VatSettingDto>> {
  const { getRequest } = useCommon();

  const urlSearchParams = new URLSearchParams();

  urlSearchParams.append("searchTerm", queryParams.searchTerm);
  urlSearchParams.append("currentPage", queryParams.currentPage.toString());
  urlSearchParams.append("pageSize", queryParams.pageSize.toString());

  const response = await getRequest({ path: `/admin/mandants/${mandantId}/vatSettings/`, urlSearchParams });
  const result = await response.json();
  return result;
}

interface CreateOrUpdateVatSettingParams {
  mandantId: number;
  vatSettingInputDto: VatSettingInputDto;
}
export async function createOrUpdateVatSetting({
  mandantId,
  vatSettingInputDto,
}: CreateOrUpdateVatSettingParams): Promise<VatSettingDto> {
  const { postRequest } = useCommon();

  const response = await postRequest({
    path: `/admin/mandants/${mandantId}/vatSettings/`,
    data: JSON.stringify(vatSettingInputDto),
  });
  const result = await response.json();
  return result;
}

interface DeleteVatSettingParams {
  mandantId: number;
  vatSettingId: number;
}

export async function deleteVatSetting({ mandantId, vatSettingId }: DeleteVatSettingParams) {
  const { deleteRequest } = useCommon();
  await deleteRequest({ path: `/admin/mandants/${mandantId}/vatSettings/${vatSettingId}` });
}
