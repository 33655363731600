<template>
  <div
    class="bg-lightTertiaryColor px-sm text-darkPrimaryTextColor dark:bg-darkTertiaryColor box-border flex h-fit w-fit max-w-[10rem] items-center whitespace-nowrap rounded py-1 text-sm"
    v-bind="$attrs"
  >
    <span class="overflow-hidden text-ellipsis">
      <slot />
    </span>
  </div>
</template>
