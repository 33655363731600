<template>
  <EditView :title="headerTitle" :on-mounted-handler="onMountedHandler">
    <template v-if="vatSetting && vatSetting.id" #navigationButtons>
      <NavigationActionButton :title="$t('message.deleteRecord')" :icon="Icon.DELETE" @click="deleteRecord" />
    </template>
    <template v-if="vatSetting">
      <FieldGroup>
        <FormElement :title="$t('message.active')" instance-path="/state">
          <StateActiveInactiveRadioButton v-model="vatSetting.state" />
        </FormElement>
        <FormElement :title="$t('message.vatSetting.description')" instance-path="/description">
          <BaseInput v-model="vatSetting.description" />
        </FormElement>
        <FormElement :title="$t('message.vatSetting.rate')" instance-path="/vatRate">
          <BaseInput v-model="vatSetting.vatRate" :disabled="!!vatSetting.id" type="number" min="0" />
        </FormElement>
        <FormElement :title="$t('message.vatSetting.type')" instance-path="/type">
          <RadioButtonGroup vertical>
            <BaseRadioButton v-model="vatSetting.type" :value="null">
              {{ $t("message.vatSetting.noTypeAssigned") }}
            </BaseRadioButton>
            <BaseRadioButton v-model="vatSetting.type" :value="VatSettingType.ACCOMMODATION">
              {{ $t(`vatSettingType.${VatSettingType.ACCOMMODATION}`) }}
            </BaseRadioButton>
            <BaseRadioButton v-model="vatSetting.type" :value="VatSettingType.LOCAL_TAX">
              {{ $t(`vatSettingType.${VatSettingType.LOCAL_TAX}`) }}
            </BaseRadioButton>
          </RadioButtonGroup>
        </FormElement>
        <FormElement :title="$t('message.vatSetting.cashRegisterDepartment')" instance-path="/cashRegisterDepartment">
          <BaseInput v-model="vatSetting.cashRegisterDepartment" :empty-value="null" type="number" min="0" />
        </FormElement>
      </FieldGroup>
    </template>
    <SaveCloseComponent
      @save="saveRecord({ shouldReturnToParent: false })"
      @save-close="saveRecord({ shouldReturnToParent: true })"
    />
  </EditView>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, Ref, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Icon } from "../../assets/icons/Icon";
import BaseInput from "../../components/BaseInput.vue";
import BaseRadioButton from "../../components/BaseRadioButton.vue";
import EditView from "../../components/EditView.vue";
import FieldGroup from "../../components/FieldGroup.vue";
import FormElement from "../../components/FormElement.vue";
import NavigationActionButton from "../../components/NavigationActionButton.vue";
import RadioButtonGroup from "../../components/RadioButtonGroup.vue";
import StateActiveInactiveRadioButton from "../../components/StateActiveInactiveRadioButton.vue";
import { State } from "../../enums/State";
import { initAjvErrors } from "../../errors/useErrors";
import { getGlobalTranslation } from "../../i18n";
import { IGenericSaveParams } from "../../interfaces/IParams";
import { MandantDto } from "../../mandant/dto.mandant";
import { useMandant } from "../../mandant/service.mandant";
import { getUserConfirmationAsBoolean, setToastMessage, setToastMessageLoading } from "../../util/SweetalertHelper";
import { mapVatSettingDtoToVatSettingInputDto, VatSettingInputDto } from "../dto.vatSetting";
import { VatSettingType } from "../enum.vatSettingType";
import { createOrUpdateVatSetting, deleteVatSetting, getVatSetting } from "../service.vatSetting";

const SaveCloseComponent = defineAsyncComponent(() => import("../../components/SaveCloseComponent.vue"));

const headerTitle = computed(() => {
  if (!vatSetting.value?.id) {
    return `${getGlobalTranslation({ message: "pages.settings" })} - ${getGlobalTranslation({ message: "pages.vatSetting.edit.new" })}`;
  }

  return `${getGlobalTranslation({ message: "pages.settings" })} - ${vatSetting.value.description}`;
});

const route = useRoute();
const router = useRouter();
const vatSettingId = computed(() =>
  Array.isArray(route.params["vatSettingId"]) ? route.params["vatSettingId"][0] : route.params["vatSettingId"],
);

const { currentMandant } = useMandant();
const mandant = currentMandant as Ref<MandantDto>;

const vatSetting = ref<undefined | VatSettingInputDto>(undefined);

async function refreshData() {
  if (vatSettingId.value === "0") {
    vatSetting.value = {
      description: "",
      vatRate: 0,
      state: State.ACTIVE,
      cashRegisterDepartment: null,
      type: null,
      id: 0,
    };
  } else {
    const vatSettingDto = await getVatSetting({ vatSettingId: vatSettingId.value, mandantId: mandant.value.id });
    vatSetting.value = mapVatSettingDtoToVatSettingInputDto(vatSettingDto);
  }
}

async function saveRecord({ shouldReturnToParent }: IGenericSaveParams) {
  if (vatSetting.value === undefined) {
    return;
  }

  setToastMessageLoading();

  const savedVatSettingDto = await provideAjvErrors(createOrUpdateVatSetting)({
    vatSettingInputDto: vatSetting.value,
    mandantId: mandant.value.id,
  });

  setToastMessage({ icon: "success", title: getGlobalTranslation({ message: "message.recordSaved" }) });

  if (shouldReturnToParent) {
    router.push({ name: "vatSettingList" });
  } else {
    await router.replace({
      name: "vatSettingEdit",
      params: {
        mandantId: mandant.value.id,
        vatSettingId: savedVatSettingDto.id,
      },
    });
    await refreshData();
  }
}

async function deleteRecord() {
  if (!vatSetting.value) {
    throw new Error("Vat Setting not found");
  }

  const isConfirmed = await getUserConfirmationAsBoolean();

  if (isConfirmed) {
    setToastMessageLoading();

    if (vatSetting.value.id) {
      await deleteVatSetting({ vatSettingId: vatSetting.value.id, mandantId: mandant.value.id });

      setToastMessage({ icon: "success", title: getGlobalTranslation({ message: "message.recordDeleted" }) });

      await router.push({ name: "vatSettingList" });
    }
  }
}

async function onMountedHandler() {
  await refreshData();
}

const { provideAjvErrors } = initAjvErrors();
</script>
