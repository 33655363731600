<template>
  <label
    class="flex w-fit items-center bg-inherit"
    :class="{
      '!w-full': vertical,
      '!pointer-events-none': $attrs.disabled,
    }"
  >
    <input
      v-model="modelValue"
      type="radio"
      :checked="modelValue === value"
      :name="name ?? ''"
      :value="value"
      class="border-lightSecondaryColor bg-lightSecondaryColor p-xs checked:bg-primary dark:checked:bg-primary peer cursor-pointer appearance-none rounded-full shadow-inner shadow-gray-300 checked:border-[4px] checked:p-[6px] checked:shadow-none disabled:!cursor-not-allowed disabled:!bg-opacity-60 disabled:!shadow-none dark:border-neutral-900 dark:bg-neutral-900 dark:shadow-black"
      :class="{
        'isInTabsComponent:!border-lightPrimaryColor isInTabsComponent:!bg-lightPrimaryColor isInTabsComponent:shadow-none isInTabsComponent:checked:!bg-primary isInTabsComponent:dark:!border-neutral-900 isInTabsComponent:dark:!bg-neutral-900 isInTabsComponent:dark:checked:!bg-primary': true,
      }"
      v-bind="$attrs"
      @input="clearErrors"
    />
    <span
      class="peer-disabled:text-lightTertiaryColor peer-disabled:dark:text-darkTertiaryColor cursor-pointer pl-2 peer-disabled:!cursor-not-allowed"
    >
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<script setup lang="ts" generic="T">
import { InputHTMLAttributes, inject } from "vue";

import { RadioGroupName, RadioGroupVertical } from "../components/RadioButtonGroup.vue";
import { useErrors } from "../errors/useErrors";

interface Props extends /* @vue-ignore */ InputHTMLAttributes {
  value: T;
  name?: string;
  label?: string;
}
const props = defineProps<Props>();
const modelValue = defineModel<undefined | T>();

const name = inject(RadioGroupName) ?? props.name;
const vertical = inject(RadioGroupVertical);

const { clearErrors } = useErrors();
</script>
