<template>
  <WrapperBox>
    <template v-if="!isLoadingView && !error">
      <NavigationComponent
        v-if="$slots.navigationButtons || backToReservationOrRequestData !== null || shouldShowActivityLogButton"
        class="mb-sm"
      >
        <slot name="navigationButtons" />
        <NavigationLinkButton
          v-if="backToReservationOrRequestData !== null"
          :icon="Icon.NOTES_BOOK"
          :title="
            backToReservationOrRequestData.type === ReservationType.REQUEST
              ? $t('pages.reservation.backToRequest')
              : $t('pages.reservation.backToReservation')
          "
          :to="backToReservationOrRequestData.link"
        />
        <ActivityLogLinkButton v-if="shouldShowActivityLogButton" :current-route-activity-log-metadata />
      </NavigationComponent>
      <slot name="title">
        <HeaderText :class="props.legends ? 'mb-sm md:mb-md' : 'mb-lg md:mb-xl'">
          {{ props.title }}
        </HeaderText>
        <LegendComponent v-if="props.legends" :legends="props.legends" />
      </slot>
      <div class="gap-lg md:gap-xl grid">
        <slot />
      </div>
    </template>
    <div v-if="isLoadingView">
      {{ $t("message.loading") }}
    </div>
    <div v-if="error">
      <HeaderText>
        {{ $t("message.pageCouldNotBeLoaded") }}
      </HeaderText>
      {{ error.message }}
    </div>
  </WrapperBox>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { Icon } from "../assets/icons/Icon";

import ActivityLogLinkButton from "../activityLog/components/ActivityLogLinkButton.vue";
import HeaderText from "../components/HeaderText.vue";
import NavigationComponent from "../components/NavigationComponent.vue";
import WrapperBox from "../components/WrapperBox.vue";
import { backToReservationOrRequestData } from "../composition-functions/reservation";
import { useUser } from "../composition-functions/user";
import { UserType } from "../composition-functions/user/UserType";
import { useView } from "../composition-functions/view";
import { ReservationType } from "../reservation/enum.ReservationType";
import LegendComponent from "./LegendComponent.vue";
import { Legend } from "./LegendHint.vue";
import NavigationLinkButton from "./NavigationLinkButton.vue";

const { userType } = useUser();

const currentRoute = useRoute();

const currentRouteActivityLogMetadata = computed(() => {
  return currentRoute.meta.activityLogs ? currentRoute.meta.activityLogs(currentRoute) : undefined;
});

const shouldShowActivityLogButton = computed(
  () => currentRouteActivityLogMetadata.value && userType.value === UserType.SUPER_ADMIN,
);

const props = defineProps<{
  title: undefined | string;
  legends?: Legend[];
  onMountedHandler?: () => void | Promise<void>;
}>();

const { isLoadingView, error } = useView(props.onMountedHandler, () => props.title);
</script>
