<template>
  <button
    class="gap-xs bg-primary hover:bg-primary/80 inline-flex min-w-fit items-center justify-center whitespace-nowrap rounded-md px-2 text-white disabled:pointer-events-none disabled:bg-gray-600"
    :title="props.tooltipText !== undefined ? `${props.tooltipText}` : ''"
    v-bind="$attrs"
    :class="{
      'border-primary !text-lightPrimaryTextColor dark:!text-darkPrimaryTextColor border !bg-transparent': props.isHighlighted,
    }"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
import { ButtonHTMLAttributes } from "vue";

interface Props extends /* @vue-ignore */ ButtonHTMLAttributes {
  tooltipText?: string;
  isHighlighted?: boolean;
}
const props = defineProps<Props>();
</script>
