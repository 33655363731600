import { ISetLocalParameterParams, ISetUrlParameterParams } from "../interfaces/IListHelper";
import router from "../router";

export async function setLocalParameters({ defaultParams, customParams }: ISetLocalParameterParams) {
  const url = new URL(window.location.toString());
  const currentPage = url.searchParams.get("currentPage");
  const pageSize = url.searchParams.get("pageSize");
  const searchTerm = url.searchParams.get("searchTerm");

  if (defaultParams.currentPage !== undefined && currentPage !== null && isNaN(Number(currentPage)) === false) {
    defaultParams.currentPage = Number(currentPage) - 1;
  }

  if (defaultParams.pageSize !== undefined && pageSize !== null && isNaN(Number(pageSize)) === false) {
    defaultParams.pageSize = Number(pageSize);
  }

  if (defaultParams.searchTerm !== undefined && typeof searchTerm === "string") {
    defaultParams.searchTerm = decodeURI(searchTerm);
  }

  if (customParams !== undefined) {
    const requests = [];

    for (const property in customParams) {
      const value = url.searchParams.get(property);

      if (value !== null) {
        requests.push(await customParams[property](value));
      }
    }

    await Promise.all(requests);
  }
}

export async function setUrlParameters({
  defaultParams: { currentPage, pageSize, searchTerm },
  customParams,
}: ISetUrlParameterParams) {
  const queryParameters: {
    [key: string]: string | string[];
  } = {};

  if (currentPage !== undefined) {
    queryParameters.currentPage = (currentPage + 1).toString();
  }

  if (pageSize !== undefined) {
    queryParameters.pageSize = pageSize.toString();
  }

  if (searchTerm !== undefined && searchTerm.length) {
    queryParameters.searchTerm = encodeURI(searchTerm);
  }

  if (customParams !== undefined) {
    for (const property in customParams) {
      const values = customParams[property];
      if (Array.isArray(values)) {
        queryParameters[`${property}[]`] = values.map((value) => value.toString());
      } else {
        queryParameters[property] = values.toString();
      }
    }
  }

  await router.replace({ query: queryParameters });
}
