<template>
  <div class="overflow-auto">
    <template v-if="items.length > 0">
      <div
        class="bg-lightTertiaryColor table w-full border-collapse rounded-t-lg dark:bg-inherit"
        :class="{ 'blur-[2px]': props.isLoading }"
      >
        <div class="text-darkPrimaryTextColor hidden md:table-header-group">
          <div
            v-for="column in columns"
            :key="column.key"
            class="table-cell min-w-[40px] whitespace-nowrap border-b-2 p-[0.3rem]"
            :class="getClassesFromAttributes(column.attributes)"
          >
            <slot :name="`col-${column.key}`" :data="column">{{ column.title }}</slot>
          </div>
        </div>
        <div class="table-row-group border">
          <template v-for="item in items" :key="extractKey ? extractKey(item) : item">
            <slot v-bind="item" />
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div :class="{ 'blur-[2px]': props.isLoading }">
        {{ $t("message.noEntriesFound") }}
      </div>
    </template>
  </div>
</template>

<script lang="ts">
export enum ListComponentColumnAttributes {
  FILL_COLUMN = "fill_column",
  TEXT_CENTER = "text_center",
  TEXT_WRAP = "text_wrap",
}

export interface ListComponentColumn {
  key: string;
  title: string;
  attributes?: ListComponentColumnAttributes[];
}

export function getClassesFromAttributes(attributes: undefined | ListComponentColumnAttributes[]) {
  let classes = "";

  if (attributes?.includes(ListComponentColumnAttributes.FILL_COLUMN)) {
    classes += "md:w-full ";
  }

  if (attributes?.includes(ListComponentColumnAttributes.TEXT_CENTER)) {
    classes += "md:text-center ";
  }

  if (attributes?.includes(ListComponentColumnAttributes.TEXT_WRAP)) {
    classes += "md:!whitespace-normal ";
  }

  return classes;
}
</script>

<script setup lang="ts" generic="T">
import { provide } from "vue";

import { ColumnsInjectionKey } from "../components/ListComponentColumns.vue";

const props = defineProps<{
  items: T[];
  columns: ListComponentColumn[];
  isLoading?: boolean;
  extractKey: (item: T) => number | string;
}>();

provide(ColumnsInjectionKey, props.columns);
</script>
