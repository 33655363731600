<template>
  <input
    ref="inputElement"
    v-model="value"
    v-bind="$attrs"
    class="bg-lightSecondaryColor pl-xs text-lightSecondaryTextColor focus:!border-primary disabled:text-lightTertiaryColor dark:border-darkSecondaryColor dark:text-darkSecondaryTextColor disabled:dark:bg-darkPrimaryColor dark:disabled:text-darkTertiaryColor w-full max-w-[35rem] rounded-md border border-transparent shadow-inner outline-none disabled:cursor-not-allowed disabled:shadow-none dark:bg-neutral-900"
    :class="{
      '!border-red': errors,
      'isInTabsComponent:!bg-lightPrimaryColor isInTabsComponent:dark:!bg-neutral-900': true,
    }"
    @input="clearErrors"
  />
</template>

<script setup lang="ts">
import { InputHTMLAttributes, Ref, ref } from "vue";

import { watch } from "vue";
import { useErrors } from "../errors/useErrors";

interface Props extends /* @vue-ignore */ InputHTMLAttributes {
  emptyValue?: null | string;
}
const props = defineProps<Props>();
const value = defineModel<null | undefined | number | string>();

const { errors, clearErrors } = useErrors();

const inputElement: Ref<null | HTMLInputElement> = ref(null);

defineExpose({
  focus: () => {
    inputElement.value?.focus();
  },
  inputElement,
});

if (props.emptyValue !== undefined) {
  watch(value, (newValue) => {
    if (typeof newValue === "string" && newValue === "") {
      value.value = props.emptyValue;
    }
  });
}
</script>
