<template>
  <span v-if="errors && errors.length > 0" class="!text-red">
    {{ errors[0].message }}
  </span>
</template>

<script setup lang="ts">
import { useErrors } from "../errors/useErrors";

const { errors } = useErrors();
</script>
