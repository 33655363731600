<template>
  <GlobalEvents @keydown.ctrl.shift.a.prevent="goToActivityLog" />
  <NavigationLinkButton
    :icon="Icon.LOG"
    :title="$t(`pages.activityLog.title`)"
    :subtitle="$t(`pages.activityLog.subtitle`)"
    :to="activityLogRoute"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { GlobalEvents } from "vue-global-events";
import { useRouter } from "vue-router";
import { Icon } from "../../assets/icons/Icon";
import NavigationLinkButton from "../../components/NavigationLinkButton.vue";
import { ActivityLogMetadata } from "../../router";

const router = useRouter();

function goToActivityLog() {
  router.push(activityLogRoute.value);
}

const props = defineProps<{
  currentRouteActivityLogMetadata?: undefined | ActivityLogMetadata;
}>();

const activityLogRoute = computed(() => {
  return {
    name: "activityLogList",
    query: {
      entityPrimaryKeys: props.currentRouteActivityLogMetadata?.entityPrimaryKeys?.join(","),
      entities: props.currentRouteActivityLogMetadata?.entities?.join(","),
      databaseActions: props.currentRouteActivityLogMetadata?.databaseActions?.join(","),
      users: props.currentRouteActivityLogMetadata?.users?.join(","),
    },
  };
});
</script>
