<template>
  <RadioButtonGroup>
    <BaseRadioButton v-model="value" :value="State.ACTIVE">
      {{ $t("message.yes") }}
    </BaseRadioButton>
    <BaseRadioButton v-model="value" :value="State.INACTIVE">
      {{ $t("message.no") }}
    </BaseRadioButton>
  </RadioButtonGroup>
</template>

<script lang="ts" setup>
import { InputHTMLAttributes } from "vue";

import BaseRadioButton from "../components/BaseRadioButton.vue";
import RadioButtonGroup from "../components/RadioButtonGroup.vue";
import { State } from "../enums/State";

interface Props extends /* @vue-ignore */ InputHTMLAttributes {}
defineProps<Props>();
const value = defineModel<State>();
</script>
